import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllGames = (id) => {
    return dispatch => {

        axios.get('/games/round/' + id)
            .then(res => {
                dispatch(setAllGames(res.data.data));
            });
    };
};

export const setAllGames = (games) => {
    return {
        type: actionTypes.GET_ALL_GAMES,
        games: games
    };
};

export const getGameStatuses = () => {
    return dispatch => {

        axios.get('/games/statuses')
            .then(res => {
                dispatch(setGameStatuses(res.data.data));
            });
    };
};

export const setGameStatuses = (statuses) => {
    return {
        type: actionTypes.GET_GAME_STATUSES,
        statuses: statuses
    };
};

export const deleteGame = (id) => {
    return dispatch => {
        axios.delete('/games/' + id)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setDeletedGame(id));
                }
            });
    };
};

export const setDeletedGame = (id) => {
    return {
        type: actionTypes.DELETE_GAME,
        gameId: id
    };
};

export const addGame = (data) => {
    return dispatch => {
        axios.post('/games', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setAddedGame(res.data.data));
                }
            });
    };
};

export const setAddedGame = (game) => {
    return {
        type: actionTypes.ADD_GAME,
        newGame: game
    };
};

export const editGame = (data) => {
    return dispatch => {
        axios.put('/games', data)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setEditedGame(res.data.data));
                }
            });
    };
};

export const setEditedGame = (game) => {
    return {
        type: actionTypes.EDIT_GAME,
        editedGame: game
    };
};


export const getHostTeamPlayers = (id) => {
    return dispatch => {
        axios.get('/teams/' + id + '/players')
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setHostTeamPlayers(res.data.data));
                }
            });
    };
};

export const setHostTeamPlayers = (players) => {
    return {
        type: actionTypes.GET_HOST_TEAM_PLAYERS,
        players: players
    };
};



export const getGuestTeamPlayers = (id) => {
    return dispatch => {
        axios.get('/teams/' + id + '/players')
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setGuestTeamPlayers(res.data.data));
                }
            });
    };
};

export const setGuestTeamPlayers = (players) => {

    return {
        type: actionTypes.GET_GUEST_TEAM_PLAYERS,
        players: players
    };
};

export const clearGames = () => {
    return {
        type: actionTypes.CLEAR_GAMES
    }
}
