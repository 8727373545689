import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { getAllGames, deleteGame } from 'Pages/Games/actions/games.js';
import { getAllRounds } from 'Pages/Rounds/actions/rounds.js';
import { connect } from 'react-redux';
import { getBadgeColorForGameStatus, darkBlueColor, getBadgeColorForRoundStatus, convertDateWithTime, convertTime } from 'utils/Constants'
import moment from 'moment'
import List from "@material-ui/icons/List";


import AddGameForm from "Pages/Games/screens/AddGameForm"
import EditGameForm from "Pages/Games/screens/EditGameForm"
import CustomModal from "UI/CustomModal/CustomModal"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import CustomSelect from "UI/CustomSelect/CustomSelect"

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    }
};

class GamesList extends React.Component {

    state = {
        addModalOpen: false,
        editModalOpen: false,
        gameData: null,
        round: {
            id: 1,
            status: {
                id: 0
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rounds !== this.props.rounds) {

            var selected = nextProps.rounds.find((t) => {
                return t.status.id === 2;
            });

            if (selected) {
                this.props.getAllGames(selected.id);

                this.setState({ round: selected })
            }

        }
    }


    componentWillMount() {
        // if (!(this.props.games !== undefined && this.props.games.length > 0)) {
        //     this.props.getAllGames(17);
        // }
        this.props.getAllRounds(1);
    }

    editHandler = (id) => {

        var selected = this.props.games.find((t) => {
            return t.id === id;
        });
        this.setState({
            gameData: {
                ...selected,
                start_date: moment('2018-12-30 22:45:00', "YYYY-MM-DD HH:mm:ss"),
            },
            editModalOpen: true,
            addModalOpen: false
        });
    }

    deleteHandler = (id) => {
        this.props.deleteGame(id);
    }

    addHandler = (id) => {
        this.setState({ editModalOpen: false, addModalOpen: true });
    }

    closeAddModal = () => {
        this.setState({ addModalOpen: false });
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false });
    }

    openGameStat = (id) => {
        this.props.history.push('/game/stats/' + id);
    }

    roundChangedHandler = (id) => {
        var selected = this.props.rounds.find((t) => {
            return t.id === id;
        });
        this.props.getAllGames(selected.id);
        this.setState({ round: selected });
    }

    render() {
        const { classes } = this.props;
        let mappedTeams = [];

        if (this.props.games.length > 0) {

            mappedTeams = this.props.games.map(t => {
                const status = <Badge color={getBadgeColorForGameStatus(t.status.id)}>{t.status.name}</Badge>;
                const startTime = convertDateWithTime(t.start_time) //new Date(t.start_time).toLocaleString("en-US");
                const hostLogo = <ImageIcon image={t.host_team.logo} />
                const guestLogo = <ImageIcon image={t.guest_team.logo} />
                const result = t.status.id === 1 ? convertTime(t.start_time) : t.host_score + " : " + t.guest_score;
                const stat = (<TooltipButton labelName="Статистика" onClick={() => this.openGameStat(t.id)}> <List /></TooltipButton>);
                return {
                    tableData: [startTime, t.host_team.name, hostLogo, result, guestLogo, t.guest_team.name, status, stat],
                    id: t.id
                };
            });
        }

        let mappedRounds = [];
        if (this.props.rounds.length > 0) {
            mappedRounds = this.props.rounds.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name}</span>
                            <Badge color={getBadgeColorForRoundStatus(t.status.id)} >{t.status.name}</Badge>
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    <AddGameForm onClose={this.closeAddModal} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <EditGameForm
                        game={this.state.gameData}
                        onClose={this.closeEditModal}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Мачове: </h4>

                            </CardHeader>
                            <CardBody>
                                <CustomSelect
                                    labelName="Кръг"
                                    selectData={mappedRounds}
                                    disabled={true}
                                    value={this.state.round.id}
                                    onChange={this.roundChangedHandler}
                                />
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Начало", "Домакин", "   ", "  ", "   ", "Гост", "Статус", "   "]}
                                    tableData={mappedTeams}

                                // actionButtons={mappedActionButtons}
                                // editHandler={this.editHandler}
                                // deleteHandler={this.deleteHandler}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        games: state.games.games,
        rounds: state.rounds.rounds
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllGames: (id) => dispatch(getAllGames(id)),
        deleteGame: id => dispatch(deleteGame(id)),
        getAllRounds: (id) => dispatch(getAllRounds(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GamesList));
